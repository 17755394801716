import {DashboardLayout} from "src/layouts/dashboard/dashboard-layout";
import {lazy, Suspense} from "react";
import {Outlet} from "react-router-dom";
import {UnsubscribedUsers} from "src/pages/dashboard/unsubscribed-users";
import {NewSurvey} from "src/pages/dashboard/newsurvey";
import {FindParticipants} from "src/pages/dashboard/findparticipants";
import {SettingCategory} from "src/pages/dashboard/settingcategory";
import {ParticipantRequests} from "src/pages/dashboard/super/participant-requests";
import {ParticipantRequestDetailsPage} from "src/pages/dashboard/super/participant-requests/participant-request-details";
import ExcellToSurvey from "src/pages/dashboard/excelltosurvey";
import {FindParticipantsPaymentRequest} from "src/pages/dashboard/super/participantpaymentrequest";
import {ParticipantPaymentRequestDetail} from "src/pages/dashboard/super/participantpaymentrequest/participant-payment-request";

const SurveyListPage = lazy(() => import("src/pages/admin/surveys"));
const TeamListPage = lazy(() => import("src/pages/admin/teams"));
const TeamDetailsPage = lazy(() => import("src/pages/admin/teams/team-details"));

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "surveys",
        element: <SurveyListPage />,
      },
      {
        path: "unsubscribed-users",
        element: <UnsubscribedUsers />,
      },
      {
        path: "newsurvey",
        element: <NewSurvey />,
      },
      {
        path: "findparticipants",
        element: <FindParticipants />,
      },
      {
        path: "settingcategory",
        element: <SettingCategory />,
      },
      {
        path: "findpartrequests",
        children: [
          {
            index: true,
            element: <ParticipantRequests />,
          },
          {
            path: ":id",
            element: <ParticipantRequestDetailsPage />,
          },
        ],
      },
      {
        path: "excelltosurvey",
        element: <ExcellToSurvey />,
      },
      {
        path: "participantPaymentRequest",
        children: [
          {
            index: true,
            element: <FindParticipantsPaymentRequest />,
          },
          {
            path: ":id",
            element: <ParticipantPaymentRequestDetail />,
          },
        ],
      },
      {
        path: "teams",
        children: [
          {
            index: true,
            element: <TeamListPage />,
          },
          {
            path: ":id",
            element: <TeamDetailsPage />,
          },
        ],
      },
    ],
  },
];
