import React, {useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  IconButton,
  Box,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {paths} from "src/pages/paths";
import {superRewardService} from "src/services/super/project/reward";
import {RewardRequest} from "src/entities/project/reward-request";

export const FindParticipantsPaymentRequest = () => {
  const {t} = useTranslation();
  const [paymentRequests, setPaymentRequests] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    superRewardService.listRewardRequests(1).then(response => {
      const results = response.results.map(res => new RewardRequest(res));
      setPaymentRequests(results);
    });
  }, []);

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom sx={{marginTop: 4, marginBottom: 3}}>
        {t("participantPaymentRequests.participantPaymentRequests")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("participantPaymentRequests.requestedStatus")}</TableCell>
              <TableCell>{t("participantPaymentRequests.participantEmail")}</TableCell>
              <TableCell>{t("participantPaymentRequests.participantId")}</TableCell>
              <TableCell>{t("participantPaymentRequests.requestedAmount")}</TableCell>
              <TableCell>{t("participantPaymentRequests.requestedMarket")}</TableCell>
              <TableCell>
                {t("participantPaymentRequests.participantCompletedSurveyDuration")}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentRequests.map(paymentRequest => (
              <TableRow key={paymentRequest.id} hover style={{cursor: "pointer"}}>
                <TableCell>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <FiberManualRecordIcon
                      fontSize="small"
                      // Duruma göre renk ayarı
                      color={
                        paymentRequest.status === "approved"
                          ? "success"
                          : paymentRequest.status === "pending"
                            ? "warning"
                            : "error" // rejected için kırmızı
                      }
                      sx={{marginRight: 1}}
                    />
                    {paymentRequest.status}
                  </Box>
                </TableCell>
                <TableCell>{paymentRequest.contact}</TableCell>
                <TableCell>{paymentRequest.participant_id}</TableCell>
                <TableCell>${paymentRequest.amount}</TableCell>
                <TableCell>{paymentRequest.type}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() =>
                      navigate(
                        paths.dashboard.participantPaymentRequest.details.replace(
                          ":id",
                          paymentRequest.id,
                        ),
                      )
                    }
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
