export const paths = {
  about: "about",
  contact: "contact",
  home: "/",
  giftcodesinstructions: "giftcodesinstructions",
  survey: "/survey",
  auth: {
    login: "/auth/login",
  },
  dashboard: {
    index: "/dashboard",
    surveys: {
      list: "/dashboard/surveys",
    },
    teams: {
      list: "/dashboard/teams",
      details: "/dashboard/teams/:slug",
      edit: "/dashboard/teams/:customerId/edit",
    },
    participantRequests: {
      list: "/dashboard/findpartrequests",
      details: "/dashboard/findpartrequests/:id",
    },
    excelltosurvey: "/dashboard/excelltosurvey",
    participantPaymentRequest: {
      list: "/dashboard/participantpaymentrequest",
      details: "/dashboard/participantpaymentrequest/:id",
    },
  },
  surveyBuilder: {
    edit: "/survey-builder/:uuid/edit",
    surveyFlow: "/survey-builder/:uuid/survey-flow-builder",
    preview: "/survey-builder/:uuid/preview",
    distributions: "/survey-builder/:uuid/distributions",
    results: "/survey-builder/:uuid/results",
    analyticsDashboard: "/survey-builder/:uuid/analytics",
  },
};
