import {useRouter} from "src/hooks/use-router";
import {useAuth} from "src/hooks/use-auth";
import {paths} from "src/pages/paths";
import {useEffect} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/system";
import HelpBubble from "src/pages/main/contact/help-buble";
import {Footer} from "../main/footer";

export const AuthLayout = props => {
  const {children} = props;
  const router = useRouter();
  const auth = useAuth();

  const scrollableStyle = {
    overflowY: "auto", // Only show scrollbar when necessary
    maxHeight: "100vh", // 100% of the viewport height
    boxSizing: "content-box", // Ensures padding doesn't add to the width
    marginRight: "-15px", // Adjust this to align content
  };

  useEffect(() => {
    if (auth.state.isAuthenticated && auth.state.user) {
      router.push(paths.dashboard.surveys.list);
    }
  }, [auth.state]);

  return (
    <div style={scrollableStyle}>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flex: "1 1 auto",
          flexDirection: {
            xs: "column-reverse",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "#ad1445",
            backgroundPosition: "top center",
            backgroundRepeat: "no-repeat",
            color: "common.white",
            display: "flex",
            flex: {
              xs: "0 0 auto",
              md: "1 1 auto",
            },
            height: "100vh", // Full height of the viewport
            maxHeight: {
              xs: "30vh", // Maximum height for mobile devices
              md: "100%", // No restriction for larger screens
            },
            padding: {
              xs: 2, // Small padding for mobile devices
              md: 0, // No padding for larger screens
            },

            justifyContent: "center",
            p: {
              xs: 4,
              md: 8,
            },
          }}
        >
          <Box maxWidth="md">
            <img
              className="logo"
              src={"/assets/Logo_dynamic.gif"}
              alt="Galata Anket logo"
              width="400"
              height="auto"
            />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flex: {
              xs: "1 1 auto",
              md: "0 0 auto",
            },
            flexDirection: "column",
            justifyContent: {
              md: "center",
            },
            maxWidth: "100%",
            p: {
              xs: 4,
              md: 8,
            },
            width: {
              md: 600,
            },
          }}
        >
          <Box sx={{mb: 4}}>
            <Stack
              alignItems="center"
              // component={RouterLink}
              direction="row"
              display="inline-flex"
              // href={paths.index}
              spacing={1}
              sx={{textDecoration: "none"}}
            >
              <Box
                sx={{
                  color: "text.primary",
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  fontSize: 14,
                  fontWeight: 800,
                  letterSpacing: "0.3px",
                  lineHeight: 2.5,
                  "& span": {
                    color: "primary.main",
                  },
                }}
              >
                Galata Anket <span>Platformu</span>
              </Box>
            </Stack>
          </Box>
          {children}
        </Box>
      </Box>
      <Footer />
      <HelpBubble />
    </div>
  );
};
